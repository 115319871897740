import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <h1 className='name'>CodeSamosa</h1>
      <h1 className='message'>Coming Soon...</h1>
    </div>
  );
}

export default App;
